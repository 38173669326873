import { NeuButton } from 'neumorphism-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../../colors'

export const BatchFinderRouteLink = ({
  label,
  to,
  icon,
  fontSize,
  disabled,
  subLabel,
  glowText,
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const glowTextStyle = {
    background: '#FF0000',
    background:
      'linear-gradient(to right, #FF0000 0%, #FF8E4D 90%, #FFAD33 100%)',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  }

  return (
    <div
      class="noselect"
      style={{
        display: 'block',
        marginBottom: '25px',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <NeuButton
        style={{
          width: '70vw',
          height: '50px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: colors.black,
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
          textDecoration: disabled ? 'line-through' : 'none',
        }}
        color={colors.offWhite}
        distance={2}
        onClick={() => {
          navigate({ pathname: to, search: searchParams.toString() })
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: '0px 15px',
            alignItems: 'center',
          }}
        >
          {icon}
          <div
            style={{
              marginRight: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={
                glowText
                  ? {
                      color: colors.black,
                      fontSize: !!fontSize ? fontSize : 'auto',
                      fontWeight: 800,
                      ...glowTextStyle,
                    }
                  : {
                      color: colors.black,
                      fontSize: !!fontSize ? fontSize : 'auto',
                    }
              }
            >
              {label}
            </p>
            {!!subLabel && (
              <p
                style={{
                  color: colors.gray300,
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {subLabel}
              </p>
            )}
          </div>
        </div>
      </NeuButton>
    </div>
  )
}
