import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'
import './App.css'
import { colors } from './colors'
import { AccessAccount } from './pages/access-account/AccessAccount'
import { BatchFinder } from './pages/batchfinder/BatchFinder'
import { DoorDash } from './pages/doordash/DoorDash'
import { Home } from './pages/home/Home'
import { LicenseInfo } from './pages/license/LicenseInfo'
import { REACT_APP_PAYPAL_CLIENT_ID } from './access_keys'
import { PurchaseOneDay } from './pages/paypal/PurchaseOneDay'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { PurchaseSevenDay } from './pages/paypal/PurchaseSevenDay'
import { PurchaseThirtyDay } from './pages/paypal/PurchaseThirtyDay'
import { PaymentComplete } from './pages/paypal/PaymentComplete'
import { getLicense } from './hooks/getLicense'
import { LogOutButton } from './components/LogOutButton'
import { DoorDashSetup } from './pages/doordash/DoorDashSetup'
import { LoadingSpinner } from './components/LoadingSpinner'
import { overrideThemeVariables } from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css'
import { checkIsLoggedIn } from './hooks/checkIsLoggedIn'
import doordashIcon from './icons/doordashIcon.png'
import { Account } from './pages/account/Account'
import { Alert, Snackbar } from '@mui/material'
import { PWAPromptiOS } from './components/PWAPromptiOS'
import { GlobalHomeButton } from './components/GlobalHomeButton'
import { getMobileOS } from './helpers.js/getMobileOS'
import { BatchFinderSetup } from './pages/batchfinder/BatchFinderSetup'
import { BatchFinderApp } from './pages/batchfinder/BatchFinderApp'
import { BatchFinderProfiles } from './pages/batchfinder/BatchFinderProfiles'
import { BatchFinderActive } from './pages/batchfinder/BatchFinderActive'
import { BatchFinderGuide } from './pages/batchfinder/BatchFinderGuide'
import { BatchFinderFAQ } from './pages/batchfinder/BatchFinderFAQ'
import { Referral } from './pages/referral/Referral'
import { AlertDialog } from './components/AlertDialog'
import { getViews } from './hooks/getViews'
import { BatchFinderSetupVideoPage } from './pages/batchfinder/BatchFinderSetupVideoPage'
import { JoinTelegramGroupDialog } from './components/JoinTelegramGroupDialog'
import { putViews } from './hooks/putViews'
import { SplashScreen } from './pages/splash/SplashScreen'
import { getPricing } from './hooks/getPricing'
import { BatchFinderService } from './pages/batchfinder/BatchFinderService'
import { PurchasePremiumOneDay } from './pages/paypal/batchfinder-premium/PurchasePremiumOneDay'
import { PurchasePremiumThirtyDay } from './pages/paypal/batchfinder-premium/PurchasePremiumThirtyDay'
import { PurchasePremiumSevenDay } from './pages/paypal/batchfinder-premium/PurchasePremiumSevenDay'
import { BlogHome } from './pages/blog/BlogHome'
import { WhatAreInstacartBots } from './pages/blog/blogs/WhatAreInstacartBots'
import { InstacartBotDownload2022 } from './pages/blog/blogs/InstacartBotDownload2022'
import { InstacartBotDownload2023 } from './pages/blog/blogs/InstacartBotDownload2023'
import { InstacartBatchGrabber } from './pages/blog/blogs/InstacartBatchGrabber'
import { Onboarding } from './pages/onboarding/Onboarding'
import { BatchFinderSelfie } from './pages/batchfinder/BatchFinderSelfie'

export const App = () => {
  const [accountId, setAccountId] = useState('')
  const [sessionId, setSessionId] = useState()
  const [currentLicense, setCurrentLicense] = useState()
  const [licenseUse, setLicenseUse] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [maintenance, setMaintenance] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState()
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false)
  const [showPWAPrompt, setShowPWAPrompt] = useState(true)
  const [copySnackbarName, setCopySnackbarName] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState('Oops, something went wrong')
  const [alertDescription, setAlertDescription] = useState(
    'Sorry for the inconvenience.',
  )
  const [alertType, setAlertType] = useState('client_error')
  const [checkedLicense, setCheckedLicense] = useState(false)
  const [userViews, setUserViews] = useState()
  const [isGettingUserViews, setIsGettingUserViews] = useState(false)
  const [joinTelegramGroupDialogVisible, setJoinTelegramGroupDialogVisible] =
    useState()
  const [licensePricing, setLicensePricing] = useState()
  const [isGettingLicensePricing, setIsGettingLicensePricing] = useState(false)
  const [acceptedBatchSound, setAcceptedBatchSound] = useState(new Audio())

  useEffect(() => {
    overrideThemeVariables({
      '--light-bg': colors.offWhite,
      '--light-bg-dark-shadow': '#b5b5b5',
      '--light-bg-light-shadow': '#ffffff',
    })
  })

  //AIDOC: Save users session ID in localStorage
  const saveSessionId = (sessionId) => {
    if (!sessionId) {
      localStorage.removeItem('session_id')
      setSessionId()
      return
    }
    localStorage.setItem('session_id', sessionId)
    setSessionId(sessionId)
  }

  const handleCopySnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setCopySnackbarOpen(false)
  }

  //AIDOC: Handle log out of account process
  const handleLogOut = () => {
    setAccountId()
    setCurrentLicense()
    setLicenseUse()
    saveSessionId(undefined)
    window.location.reload(true)
  }

  //AIDOC: Show loading spinner
  const handleSetIsLoading = (newIsLoading) => {
    if (isLoading && newIsLoading) {
      return
    }
    setIsLoading(newIsLoading)
  }

  useEffect(() => {
    if (sessionId === undefined) {
      const session_id = localStorage.getItem('session_id')
      if (session_id) {
        setSessionId(session_id)
      }
    }
  })

  //AIDOC: Check user has logged into their account and session ID is valid
  const handleCheckLoggedIn = async () => {
    if (!sessionId && isLoggedIn) {
      setIsLoggedIn(false)
      return
    }
    if (!sessionId && isLoggedIn === undefined) {
      setIsLoggedIn(false)
      return
    }
    const loggedInStatus = await checkIsLoggedIn(sessionId)
    if (loggedInStatus.isLoggedIn === undefined) {
      //Undetermined
      return
    }
    if (!loggedInStatus.isLoggedIn) {
      setUserViews({})
    }
    if (isLoggedIn === undefined || loggedInStatus.isLoggedIn !== isLoggedIn) {
      if (isLoggedIn && !loggedInStatus.isLoggedIn) {
        handleLogOut()
      } else {
        setAccountId(loggedInStatus.accountId)
        setIsLoggedIn(loggedInStatus)
      }
    }
  }

  const handleGetUserViews = async () => {
    const res = await getViews(sessionId)
    setIsGettingUserViews(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        handleLogOut()
      } else {
        // alert('Error getting views:', JSON.stringify(res))
      }
    } else if (res.statusCode === 200) {
      setUserViews(res.body.views)
    } else {
      // alert('Error getting views:', JSON.stringify(res))
    }
  }

  const handlePutUserViews = async (updatedViews) => {
    if (!sessionId) return
    const res = await putViews(sessionId, updatedViews)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        handleLogOut()
      } else {
        // alert('Error updating views:', JSON.stringify(res))
      }
    } else if (res.statusCode === 200) {
      setUserViews(res.body.views)
    } else {
      // alert('Error updating views:', JSON.stringify(res))
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      handleCheckLoggedIn()
    }, 30000)
    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (isLoggedIn === undefined) {
      handleCheckLoggedIn()
    }
  })

  //AIDOC: Retrieve current license for account via session ID
  const handleGetCurrentLicense = async () => {
    const res = await getLicense(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      handleLogOut()
    } else if (res.statusCode === 200 || res.statusCode === 404) {
      if (JSON.stringify(currentLicense) !== JSON.stringify(res.body.license)) {
        setCurrentLicense(res.body.license)
      }
      if (JSON.stringify(licenseUse) !== JSON.stringify(res.body.license_use)) {
        setLicenseUse(res.body.license_use)
      }
    }
  }

  const handleGetLicensePricing = async () => {
    const res = await getPricing(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      handleLogOut()
    } else if (res.statusCode === 200) {
      setLicensePricing(res.body.pricing)
      setIsGettingLicensePricing(false)
    }
  }

  const isInStandaloneMode = () =>
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone ||
    document.referrer.includes('android-app://')

  const handleOpenCopySnackbar = (name) => {
    setCopySnackbarName(name)
    setCopySnackbarOpen(true)
  }

  const handleShowAlert = (title, desc, type) => {
    setAlertTitle(title)
    setAlertDescription(desc)
    setAlertType(type)
    setShowAlert(true)
  }

  const handleHideJoinTelegramGroupDialog = (showAgain) => {
    if (!showAgain) {
      handlePutUserViews({ telegram_group_invite: true })
    }
    setJoinTelegramGroupDialogVisible(false)
  }

  useEffect(() => {
    if (currentLicense === undefined && !!sessionId && !checkedLicense) {
      setCheckedLicense(true)
      handleGetCurrentLicense()
    }
  })

  useEffect(() => {
    handleCheckLoggedIn()
  }, [sessionId])

  useEffect(() => {
    if (sessionId && !userViews && !isGettingUserViews) {
      setIsGettingUserViews(true)
      handleGetUserViews()
    }
  })

  useEffect(() => {
    if (
      !(joinTelegramGroupDialogVisible === undefined) ||
      userViews === undefined ||
      Object.keys(userViews).length === 0 ||
      userViews?.telegram_group_invite
    )
      return
    const temp = async () => {
      await new Promise((_) => setTimeout(_, 5000))
      if (!(joinTelegramGroupDialogVisible === undefined)) return
      setJoinTelegramGroupDialogVisible(!userViews.telegram_group_invite)
    }
    temp()
  })

  useEffect(() => {
    if (sessionId && !licensePricing && !isGettingLicensePricing) {
      setIsGettingLicensePricing(true)
      handleGetLicensePricing()
    }
  })

  const global = {
    saveSessionId,
    setIsLoading: handleSetIsLoading,
    handleLogOut,
    handleCheckLoggedIn,
    showAlert: handleShowAlert,
    handleGetCurrentLicense,
    handleShowSnackbar: handleOpenCopySnackbar,
    acceptedBatchSound,
  }

  return (
    <div class="noselect">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <Helmet>
        <style>{`body { background-color: ${colors.offWhite}; }`}</style>
        <script src="/adroll-tracking.js" type="text/javascript" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {isLoading && <LoadingSpinner />}
      <Router>
        <div>
          <Routes>
            <Route
              path="/"
              element={<SplashScreen global={global} isLoggedIn={isLoggedIn} />}
            />
            <Route
              path="/app"
              element={
                <Home
                  sessionId={sessionId}
                  accountId={accountId}
                  openCopySnackbar={() => handleOpenCopySnackbar('Account ID')}
                  userViews={userViews}
                  isGettingUserViews={isGettingUserViews}
                  isLoggedIn={isLoggedIn}
                  handleLogOut={handleLogOut}
                />
              }
            />
            <Route path="/account" element={<Account />} />
            <Route
              path="/login"
              element={
                <AccessAccount
                  global={global}
                  setAccountId={setAccountId}
                  setSessionId={saveSessionId}
                  accountId={accountId}
                />
              }
            />
            {/* <Route
              path="/doordash"
              element={
                <DoorDash
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            /> */}
            <Route
              path="/onboarding"
              element={
                <Onboarding
                  sessionId={sessionId}
                  userViews={userViews}
                  handlePutUserViews={handlePutUserViews}
                />
              }
            />
            <Route
              path="/batchfinder"
              element={
                <BatchFinder
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                  userViews={userViews}
                />
              }
            />
            <Route
              path="/batchfinder/setup"
              element={
                <BatchFinderSetup
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            />
            <Route
              path="/batchfinder/identity"
              element={
                <BatchFinderSelfie
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route
              path="/batchfinder/app"
              element={
                <BatchFinderApp
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            />
            <Route
              path="/batchfinder/service"
              element={
                <BatchFinderService
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            />
            <Route
              path="/batchfinder/active"
              element={
                <BatchFinderActive
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            />
            <Route
              path="/batchfinder/filters"
              element={
                <BatchFinderProfiles
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            />
            <Route
              path="/batchfinder/guide"
              element={
                <BatchFinderGuide
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            />
            <Route
              path="/batchfinder/guide/video"
              element={<BatchFinderSetupVideoPage />}
            />
            {/* <Route
              path="/batchfinder/faq"
              element={
                <BatchFinderFAQ
                  global={global}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  sessionId={sessionId}
                />
              }
            /> */}
            <Route
              path="/doordash/setup"
              element={
                <DoorDashSetup
                  global={global}
                  sessionId={sessionId}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                />
              }
            />
            <Route
              path="/license"
              element={
                <LicenseInfo
                  pricingConfig={licensePricing}
                  setCurrentLicense={setCurrentLicense}
                  setLicenseUse={setLicenseUse}
                  global={global}
                  currentLicense={currentLicense}
                  sessionId={sessionId}
                  licenseUse={licenseUse}
                />
              }
            />
            <Route
              path="/license/license_1"
              element={
                <PayPalScriptProvider
                  options={{
                    'client-id': REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PurchaseOneDay
                    config={licensePricing?.one_day}
                    isLoggedIn={isLoggedIn}
                    sessionId={sessionId}
                    saveSessionId={saveSessionId}
                    setCurrentLicense={setCurrentLicense}
                    global={global}
                  />
                </PayPalScriptProvider>
              }
            />
            <Route
              path="/license/license_7"
              element={
                <PayPalScriptProvider
                  options={{
                    'client-id': REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PurchaseSevenDay
                    config={licensePricing?.seven_day}
                    isLoggedIn={isLoggedIn}
                    sessionId={sessionId}
                    saveSessionId={saveSessionId}
                    setCurrentLicense={setCurrentLicense}
                    global={global}
                  />
                </PayPalScriptProvider>
              }
            />
            <Route
              path="/license/license_30"
              element={
                <PayPalScriptProvider
                  options={{
                    'client-id': REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PurchaseThirtyDay
                    config={licensePricing?.thirty_day}
                    isLoggedIn={isLoggedIn}
                    sessionId={sessionId}
                    saveSessionId={saveSessionId}
                    setCurrentLicense={setCurrentLicense}
                    global={global}
                  />
                </PayPalScriptProvider>
              }
            />
            <Route
              path="/license/license_premium_1"
              element={
                <PayPalScriptProvider
                  options={{
                    'client-id': REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PurchasePremiumOneDay
                    config={licensePricing?.one_day_premium}
                    isLoggedIn={isLoggedIn}
                    sessionId={sessionId}
                    saveSessionId={saveSessionId}
                    setCurrentLicense={setCurrentLicense}
                    global={global}
                  />
                </PayPalScriptProvider>
              }
            />
            <Route
              path="/license/license_premium_7"
              element={
                <PayPalScriptProvider
                  options={{
                    'client-id': REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PurchasePremiumSevenDay
                    config={licensePricing?.seven_day_premium}
                    isLoggedIn={isLoggedIn}
                    sessionId={sessionId}
                    saveSessionId={saveSessionId}
                    setCurrentLicense={setCurrentLicense}
                    global={global}
                  />
                </PayPalScriptProvider>
              }
            />
            <Route
              path="/license/license_premium_30"
              element={
                <PayPalScriptProvider
                  options={{
                    'client-id': REACT_APP_PAYPAL_CLIENT_ID,
                  }}
                >
                  <PurchasePremiumThirtyDay
                    config={licensePricing?.thirty_day_premium}
                    isLoggedIn={isLoggedIn}
                    sessionId={sessionId}
                    saveSessionId={saveSessionId}
                    setCurrentLicense={setCurrentLicense}
                    global={global}
                  />
                </PayPalScriptProvider>
              }
            />
            <Route
              path="/referral"
              element={
                <Referral
                  global={global}
                  sessionId={sessionId}
                  accountId={accountId}
                  currentLicense={currentLicense}
                  maintenance={maintenance}
                  openCopySnackbar={() =>
                    handleOpenCopySnackbar('Referral Link')
                  }
                  handleGetCurrentLicense={handleGetCurrentLicense}
                />
              }
            />
            <Route path="/paymentcomplete" element={<PaymentComplete />} />
            <Route path="/blog" element={<BlogHome />} />
            <Route
              path="/blog/what_are_instacart_bots"
              element={<WhatAreInstacartBots />}
            />
            <Route
              path="/blog/instacart_bot_download_2022"
              element={<InstacartBotDownload2022 />}
            />
            <Route
              path="/blog/instacart_bot_download_2023"
              element={<InstacartBotDownload2023 />}
            />
            <Route
              path="/blog/instacart_batch_grabber"
              element={<InstacartBatchGrabber />}
            />
            <Route path="*" element={<Navigate to="/" />}></Route>
          </Routes>
        </div>
        <Snackbar
          open={copySnackbarOpen}
          autoHideDuration={3000}
          onClose={handleCopySnackbarClose}
          message="Copied!"
        >
          <Alert
            onClose={handleCopySnackbarClose}
            severity="success"
            sx={{
              width: '100%',
            }}
            variant="filled"
            elevation={6}
          >
            Copied {copySnackbarName} to Clipboard
          </Alert>
        </Snackbar>
        <AlertDialog
          visible={showAlert}
          hideAlertDialog={() => setShowAlert(false)}
          alertTitle={alertTitle}
          alertDescription={alertDescription}
          alertType={alertType}
        />
        <JoinTelegramGroupDialog
          sessionId={sessionId}
          visible={joinTelegramGroupDialogVisible}
          hideDialog={handleHideJoinTelegramGroupDialog}
        />
        <GlobalHomeButton />
        {showPWAPrompt && !isInStandaloneMode() && getMobileOS() === 'iOS' && (
          <PWAPromptiOS closePWAPrompt={() => setShowPWAPrompt(false)} />
        )}
      </Router>
    </div>
  )
}
