import PaypalTemplate from './PaypalTemplate'

export const PurchaseThirtyDay = ({
  config,
  isLoggedIn,
  saveSessionId,
  sessionId,
  setCurrentLicense,
  global,
}) => {
  if (!config) return
  return (
    <PaypalTemplate
      isLoggedIn={isLoggedIn}
      productDescription={'30 Day License'}
      category={'30 Day'}
      price={config.value}
      sessionId={sessionId}
      saveSessionId={saveSessionId}
      setCurrentLicense={setCurrentLicense}
      global={global}
    />
  )
}
