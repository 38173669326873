import React, { useState } from 'react'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styles from './styles.module.css'
import { Box } from '@mui/material'
import { assignPaidLicense } from '../../hooks/assignPaidLicense'
import { colors } from '../../colors'
import { BackButton } from '../../components/BackButton'

const PaypalTemplate = ({
  isLoggedIn,
  productDescription,
  category,
  price,
  setCurrentLicense,
  saveSessionId,
  sessionId,
  global,
  isSale,
  preSalePrice,
}) => {
  const { setIsLoading } = global
  const [error, setError] = useState(null)

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const routeChange = (data) => {
    //TODO: Add querystring support here
    navigate('/paymentcomplete', {
      state: { id: 1, order: data.order },
    })
  }

  const handleApprove = async (order) => {
    //Handle fulfillment and post logic here
    const res = await fulfillPurchase(order) // Call backend function to fulfill order
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      saveSessionId('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (res.statusCode !== 200) {
      alert(
        'Error creating license. Please contact @avalonapp on Telegram. Error log:',
        res.body,
      )
      return
    } else {
      setCurrentLicense(res.body.license)
      routeChange({ order })
    }
  }

  const fulfillPurchase = async (order) => {
    const res = await assignPaidLicense(order.id, sessionId)
    return res
  }

  if (error) {
    alert(error)
  }

  return (
    <div class="noselect">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '20px',
        }}
      >
        <h1>Checkout</h1>

        <div className={styles.center}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: '3vw',
              marginRight: '3vw',
            }}
          >
            <h2>{category + ' License'}</h2>
            {isSale && price !== preSalePrice && (
              <>
                <h4
                  class="price-strikethrough"
                  style={{
                    color: colors.gray400,
                    marginRight: '5px',
                    padding: '0px 2px',
                  }}
                >
                  ${preSalePrice}
                </h4>
                <h4 style={{ textAlign: 'center' }}>${price}</h4>
              </>
            )}
            {!(isSale && price !== preSalePrice) && <h4>${price}</h4>}
          </div>
          {isSale && (
            <p
              style={{
                color: colors.red,
                fontSize: '14px',
                fontWeight: 600,
                textAlign: 'center',
                margin: '5px 0px',
              }}
            >
              UPDATE SALE ENDS 10/15 MIDNIGHT!
            </p>
          )}
          {!isLoggedIn && (
            <h1
              style={{
                color: colors.red,
                fontWeight: 'bold',
                fontSize: '20px',
                margin: '10px',
              }}
            >
              Please log into your Avalon account first
            </h1>
          )}
          {isLoggedIn && (
            <div className={styles.innerPaypal}>
              <PayPalButtons
                style={{ color: 'silver' }}
                onClick={(data, actions) => {
                  return actions.resolve()
                }}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        description: productDescription,
                        amount: {
                          value: price,
                        },
                      },
                    ],
                  })
                }}
                onApprove={async (data, actions) => {
                  const order = await actions.order.capture()
                  await handleApprove(order)
                }}
                onError={(err) => {
                  setError(err)
                  console.error('Paypal checkout error', err)
                }}
                onCancel={() => {
                  // Display cancel message
                }}
              />
            </div>
          )}
          <p>
            BatchFinder is a paid service that allows you to access and accept
            higher quality batches
            <br />
            <br />
            While our licenses are paid, new users have access to a free 3-day
            trial license.
            <br />
            <br />
            Tools developed by Avalon are undetectable. Rigorous testing is
            performed and tools are only released after long periods of
            observation.
          </p>
        </div>
        <BackButton />
      </Box>
    </div>
  )
}

export default PaypalTemplate
