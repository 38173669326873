import { useEffect, useState } from 'react'
import { getLicense } from '../../hooks/getLicense'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../colors'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { assignTrialLicense } from '../../hooks/assignTrialLicense'
import { CurrentLicense } from '../../components/CurrentLicense'
import { BackButton } from '../../components/BackButton'
import { assignGeneralLicense } from '../../hooks/assignGeneralLicense'
import { PremiumCard } from './PremiumCard'
import { StandardCard } from './StandardCard'

const handleGetLicensePricing = (config, variant, length) => {
  if (!config) return
  if (variant === 'premium') {
    switch (length) {
      case '30_days':
        return config.thirty_day_premium.value
      case '7_days':
        return config.seven_day_premium.value
      case '1_day':
        return config.one_day_premium.value
      default:
        return 'N/A'
    }
  } else if (variant === 'standard') {
    switch (length) {
      case '30_days':
        return config.thirty_day.value
      case '7_days':
        return config.seven_day.value
      case '1_day':
        return config.one_day.value
      default:
        return 'N/A'
    }
  } else {
    return 'N/A'
  }
}

const handleGetLicenseLink = (variant, length) => {
  if (variant === 'premium') {
    switch (length) {
      case '30_days':
        return './license_premium_30'
      case '7_days':
        return './license_premium_7'
      case '1_day':
        return './license_premium_1'
      default:
        return '.'
    }
  } else if (variant === 'standard') {
    switch (length) {
      case '30_days':
        return './license_30'
      case '7_days':
        return './license_7'
      case '1_day':
        return './license_1'
      default:
        return '.'
    }
  } else {
    return '.'
  }
}

export const LicenseInfo = ({
  pricingConfig,
  setCurrentLicense,
  setLicenseUse,
  global,
  currentLicense,
  sessionId,
  licenseUse,
}) => {
  const { saveSessionId, handleLogOut, showAlert } = global
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [licenseVariant, _licenseVariant] = useState('premium')
  const [licenseLength, _licenseLength] = useState('30_days')
  const [displayLicensePricing, _displayLicensePricing] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleGetCurrentLicense = async () => {
    setLoading(true)
    const res = await getLicense(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      setCurrentLicense(res.body.license)
      setLicenseUse(res.body.license_use)
    } else if (res.statusCode === 404) {
      setCurrentLicense(undefined)
      setLicenseUse(res.body.license_use)
    } else {
      console.log('Error retrieving license:', res) //TODO Error handle here
    }
    setLoading(false)
  }

  const activateTrialLicense = async () => {
    setLoading(true)
    const res = await assignTrialLicense(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      saveSessionId('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (res.statusCode !== 200) {
      alert(
        'Error activating trial license. Please contact @BatchFinderDev on Telegram. Error log:',
        res.body,
      )
    } else {
      showAlert(
        'Trial License Activated!',
        'Your trial license has been successfully activated. If this is your first time using BatchFinder, I recommend reading through the Setup Guide on the main BatchFinder page.',
        'success',
      )
      await handleGetCurrentLicense()
    }
    setLoading(false)
  }

  const activateGeneralLicense = async (licenseId, licenseDisplayName) => {
    setLoading(true)
    const res = await assignGeneralLicense(sessionId, licenseId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      saveSessionId('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (res.statusCode === 200) {
      showAlert(`${licenseDisplayName} License Activated!`, 'Enjoy!', 'success')
      await handleGetCurrentLicense()
    } else {
      alert(
        `Error activating ${licenseDisplayName} license. Please contact @BatchFinderDev on Telegram. Error log: ${res.body}`,
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentLicense === undefined && sessionId && firstLoad) {
      handleGetCurrentLicense()
      setFirstLoad(false)
    }
  })

  return (
    <div
      class="noselect"
      style={{
        backgroundColor: colors.splashDark,
        minHeight: '100vh',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
      }}
    >
      {loading && <LoadingSpinner />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ color: 'white', fontWeight: 500 }}>
            Get the Best Batches
          </h1>
          <h1
            style={{ color: colors.splashGreen /*'#21cc4d'*/, fontWeight: 800 }}
          >
            Every Time.
          </h1>
        </div>
        {currentLicense !== undefined && !displayLicensePricing && (
          <CurrentLicense currentLicense={currentLicense} />
        )}
        {!displayLicensePricing &&
          licenseUse?.returning_promo_nov_2023 === false && (
            <div
              style={{
                marginTop: '2rem',
                padding: '0.5rem 1rem',
                fontWeight: 800,
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: colors.splashGreen,
                color: colors.splashDark,
                border: 'none',
                transition: 'transform 0.1s, filter 0.1s',
                textDecoration: 'none',
              }}
              onTouchStart={(e) => {
                e.currentTarget.style.transform = 'scale(0.95)'
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style.transform = 'scale(1)'
              }}
              onClick={() => {
                activateGeneralLicense(
                  'returning_promo_nov_2023',
                  'Returning Promo License',
                )
              }}
            >
              <h3>Free 3 Day License</h3>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '0.8em',
                  paddingTop: '5px',
                }}
              >
                Thanks for coming back!
              </p>
            </div>
          )}
        {currentLicense === undefined &&
          !displayLicensePricing &&
          licenseUse?.returning_promo_nov_2023 === false && (
            <div
              style={{
                marginTop: '4rem',
                padding: '0.5rem 1rem',
                fontWeight: 800,
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: colors.splashGreen,
                color: colors.splashDark,
                border: 'none',
                transition: 'transform 0.1s, filter 0.1s',
                textDecoration: 'none',
              }}
              onTouchStart={(e) => {
                e.currentTarget.style.transform = 'scale(0.95)'
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style.transform = 'scale(1)'
              }}
              onClick={() => {
                _displayLicensePricing(true)
              }}
            >
              <h3>View Licenses</h3>
            </div>
          )}
        {currentLicense !== undefined && !displayLicensePricing && (
          <div
            style={{
              marginTop: '4rem',
              padding: '0.5rem 1rem',
              fontWeight: 800,
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              backgroundColor: colors.splashGreen,
              color: colors.splashDark,
              border: 'none',
              transition: 'transform 0.1s, filter 0.1s',
              textDecoration: 'none',
            }}
            onTouchStart={(e) => {
              e.currentTarget.style.transform = 'scale(0.95)'
            }}
            onTouchEnd={(e) => {
              e.currentTarget.style.transform = 'scale(1)'
            }}
            onClick={() => {
              _licenseVariant(currentLicense.license_variant)
              _displayLicensePricing(true)
            }}
          >
            <h3>Extend License</h3>
          </div>
        )}

        {((currentLicense === undefined &&
          licenseUse?.returning_promo_nov_2023 !== false) ||
          displayLicensePricing) && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1rem',
                width: '90%',
                justifyContent: 'space-between',
                marginBottom: '-1px', // Handles "seam"
              }}
            >
              {currentLicense?.license_variant === 'standard' && (
                <div
                  style={{
                    backgroundColor: '#FBFAF8',
                    fontSize: '0.9rem',
                    width: '35%',
                    padding: '0.5rem',
                    borderRadius: '15px 15px 0px 0px',
                    textAlign: 'center',
                    fontWeight: 400,
                    boxShadow: 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.7)',
                  }}
                  onClick={() => _displayLicensePricing(false)}
                >
                  <p>View License</p>
                </div>
              )}
              {currentLicense?.license_variant !== 'standard' && (
                <div
                  style={{
                    backgroundColor: '#FBFAF8',
                    width: '35%',
                    padding: '0.5rem',
                    borderRadius: '15px 15px 0px 0px',
                    textAlign: 'center',
                    fontWeight: licenseVariant === 'premium' ? 700 : 400,
                    boxShadow:
                      licenseVariant === 'premium'
                        ? 'none'
                        : 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.7)',
                  }}
                  onClick={() => _licenseVariant('premium')}
                >
                  <p>Premium</p>
                </div>
              )}
              {currentLicense?.license_variant !== 'premium' && (
                <div
                  style={{
                    backgroundColor: '#FBFAF8',
                    width: '35%',
                    padding: '0.5rem',
                    borderRadius: '15px 15px 0px 0px',
                    textAlign: 'center',
                    fontWeight: licenseVariant === 'standard' ? 700 : 400,
                    boxShadow:
                      licenseVariant === 'standard'
                        ? 'none'
                        : 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.7)',
                  }}
                  onClick={() => _licenseVariant('standard')}
                >
                  <p>Standard</p>
                </div>
              )}
              {currentLicense?.license_variant === 'premium' && (
                <div
                  style={{
                    backgroundColor: '#FBFAF8',
                    fontSize: '0.9rem',
                    width: '35%',
                    padding: '0.5rem',
                    borderRadius: '15px 15px 0px 0px',
                    textAlign: 'center',
                    fontWeight: 400,
                    boxShadow: 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.7)',
                  }}
                  onClick={() => _displayLicensePricing(false)}
                >
                  <p>View License</p>
                </div>
              )}
            </div>
            {licenseVariant === 'premium' && (
              <PremiumCard
                handleGetLicensePricing={handleGetLicensePricing}
                handleGetLicenseLink={handleGetLicenseLink}
                pricingConfig={pricingConfig}
              />
            )}
            {licenseVariant === 'standard' && (
              <StandardCard
                handleGetLicensePricing={handleGetLicensePricing}
                handleGetLicenseLink={handleGetLicenseLink}
                pricingConfig={pricingConfig}
              />
            )}
          </>
        )}

        <BackButton />
      </div>
    </div>
  )
}
