import { NeuDiv } from 'neumorphism-react'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ProgressCircular } from 'ui-neumorphism'
import { colors } from '../../colors'
import { getActiveBatch } from '../../hooks/instacart/getActiveBatch'
import { getStoreLocations } from '../../hooks/instacart/getStoreLocations'
import { BatchListItem } from './helpers/BatchListItem'
import { Button } from '@mui/material'
import { BatchFinderFocusBatch } from './helpers/BatchFinderFocusBatch'
import { BackButton } from '../../components/BackButton'
import { startBatchFinderService } from '../../hooks/startBatchFinderService'
import { getBatchFinderServiceSettings } from '../../hooks/getBatchFinderServiceSettings'
import { pingAlive } from '../../hooks/instacart/pingAlive'
import { stopBatchFinderService } from '../../hooks/stopBatchFinderService'
import { FakeLocationSelector } from './service-helpers/FakeLocationSelector'
import { updateBatchFinderServiceLocation } from '../../hooks/updateBatchFinderServiceLocation'

export const BatchFinderService = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
}) => {
  const {
    saveSessionId,
    handleLogOut,
    showAlert,
    handleGetCurrentLicense,
    setIsLoading,
  } = global
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [batches, _batches] = useState([])
  const [focusBatch, setFocusBatch] = useState()
  const [storeLocations, setStoreLocations] = useState()
  const [lastGetStoresTime, _lastGetStoresTime] = useState(0)
  const [lastGetLocationTime, _lastGetLocationTime] = useState(0)
  const [lastGetActiveBatchTime, setLastGetActiveBatchTime] = useState(0)
  const [lastGetServiceSettingsTime, _lastGetServiceSettingsTime] = useState(0)
  const [lastHeartbeatTime, _lastHeartbeatTime] = useState(0)
  const [lastRealLocationUpdateTime, _lastRealLocationUpdateTime] = useState(0)

  const [serviceIsRunning, _serviceIsRunning] = useState(false)

  const [realLatitude, _realLatitude] = useState()
  const [realLongitude, _realLongitude] = useState()

  const [hasRetrievedServiceSettings, _hasRetrievedServiceSettings] =
    useState(false)

  const [locationLatitude, _locationLatitude] = useState()
  const [locationLongitude, _locationLongitude] = useState()
  const [locationName, _locationName] = useState()
  const [serviceStopReason, _serviceStopReason] = useState()

  const [locationSelectorVisible, _locationSelectorVisible] = useState(false)
  const [locationIsReal, _locationIsReal] = useState()

  const handleGetStoreLocations = async (latitude, longitude) => {
    const res = await getStoreLocations(sessionId, latitude, longitude, 5)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'License not found') {
        await handleGetCurrentLicense()
        navigate({ pathname: '/app', search: searchParams.toString() })
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      }
    } else if (res.statusCode === 200) {
      setStoreLocations(res.body.stores)
    }
  }

  const handleGetActiveBatch = async () => {
    const res = await getActiveBatch(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Rate limited') {
        setLastGetActiveBatchTime(lastGetActiveBatchTime + 60000) //60 second pause
      }
    } else if (res.statusCode === 200) {
      if (res.body.batch) {
        navigate({
          pathname: '/batchfinder/active',
          search: searchParams.toString(),
        })
      }
    }
  }

  const handleStartService = async () => {
    setIsLoading(true)
    const res = await startBatchFinderService(sessionId)
    setIsLoading(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Location not provided') {
        showAlert(
          'Missing Location Settings',
          'Make sure to choose your location settings before starting BatchFinder',
          'client_error',
        )
      } else if (res.body === 'Already running') {
        _serviceIsRunning(true)
      } else {
        showAlert(
          'Error starting BatchFinder',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      handleGetServiceSettings()
      _serviceIsRunning(true)
    } else if (res.statusCode === 0) {
      // No-op (just try again)
    } else {
      showAlert(
        'Error starting BatchFinder',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleStopService = async () => {
    const res = await stopBatchFinderService(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        showAlert(
          'Error stopping BatchFinder',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      handleGetServiceSettings()
      _serviceIsRunning(false)
    } else {
      showAlert(
        'Error stopping BatchFinder',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handleGetServiceSettings = async () => {
    const res = await getBatchFinderServiceSettings(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        showAlert(
          'Error getting settings',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      const icSettings = res.body.step_settings.instacart
      if (locationIsReal !== icSettings.location_settings?.is_real_location)
        _locationIsReal(icSettings.location_settings?.is_real_location)
      if (locationName !== icSettings.location_settings?.display_name)
        _locationName(icSettings.location_settings?.display_name)
      if (locationLatitude !== icSettings.location_settings?.latitude)
        _locationLatitude(icSettings.location_settings?.latitude)
      if (locationLongitude !== icSettings.location_settings?.longitude)
        _locationLongitude(icSettings.location_settings?.longitude)
      if (serviceStopReason !== icSettings.stop_reason)
        _serviceStopReason(icSettings.stop_reason)

      _batches(icSettings.batches)

      if (
        !icSettings.step_function_id &&
        serviceIsRunning &&
        !icSettings.turbo_running
      ) {
        _serviceIsRunning(false)
        if (icSettings.stop_reason === 'Accepted batch') {
          navigate({
            pathname: '/batchfinder/active',
            search: searchParams.toString(),
          })
        }
      }

      if (!hasRetrievedServiceSettings) _hasRetrievedServiceSettings(true)
    } else if (res.statusCode === 0) {
      // No-op (user closed browser)
    } else {
      showAlert(
        'Error getting settings',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  const handlePingAlive = async () => {
    const res = await pingAlive(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Missing account profiles') {
        navigate({
          pathname: '/batchfinder/identity',
          search: searchParams.toString(),
        })
      } else if (res.body === 'Not allowed to go online') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
        alert(
          'Not allowed to go online. Please contact @BatchFinderDev on Telegram.',
        )
      }
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (new Date().getTime() > lastGetLocationTime + 30000) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (!position?.coords?.latitude || !position?.coords?.longitude) {
                alert(
                  'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
                )
                navigate({ pathname: '/app', search: searchParams.toString() })
              } else {
                _realLatitude(position.coords.latitude)
                _realLongitude(position.coords.longitude)
                _lastGetLocationTime(new Date().getTime())
              }
            },
            (error) => {
              alert(
                'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
              )
              navigate({ pathname: '/app', search: searchParams.toString() })
            },
          )
        } else {
          alert(
            'Location not enabled.\n\nHow to fix:\n1. Open the Settings app\n2. Search up "Location Services"\n3. Scroll to “Safari” and click it\n4. Set it to "While Using the App"\n5. Restart BatchFinder\n\nIf that doesn\'t work:\n1. Open the Settings app > Safari.\n2. Click "Clear History and Website Data".\n3. Restart BatchFinder\n4. Click "Allow" when asked for location.\n\nYour location is required so BatchFinder can access batches in your area.',
          )
          navigate({ pathname: '/app', search: searchParams.toString() })
        }
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  // Get store locations (for list)
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        new Date().getTime() > lastGetStoresTime + 60000 &&
        hasRetrievedServiceSettings &&
        realLatitude &&
        realLongitude &&
        !serviceIsRunning
      ) {
        _lastGetStoresTime(new Date().getTime())

        handleGetStoreLocations(realLatitude, realLongitude)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  // Check active batch
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (sessionId && new Date().getTime() > lastGetActiveBatchTime + 120000) {
        setLastGetActiveBatchTime(new Date().getTime())
        handleGetActiveBatch()
      }
    }, 2000)
    return () => clearInterval(intervalId)
  })

  // Get service settings
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const pollingRate = serviceIsRunning ? 2000 : 30000
      if (
        sessionId &&
        new Date().getTime() > lastGetServiceSettingsTime + pollingRate
      ) {
        _lastGetServiceSettingsTime(new Date().getTime())
        handleGetServiceSettings()
      }
    }, 500)
    return () => clearInterval(intervalId)
  })

  // Track user's real location
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        locationIsReal &&
        realLatitude &&
        realLongitude &&
        new Date().getTime() > lastRealLocationUpdateTime + 60000
      ) {
        _lastRealLocationUpdateTime(new Date().getTime())
        handleSaveLocation(realLatitude, realLongitude, true, 'n/a')
      }
    }, 10000)
    return () => clearInterval(intervalId)
  })

  // Ping heartbeat
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        !serviceIsRunning &&
        new Date().getTime() > lastHeartbeatTime + 30000
      ) {
        _lastHeartbeatTime(new Date().getTime())
        handlePingAlive()
      }
    }, 5000)
    return () => clearInterval(intervalId)
  })

  // Initial heartbeat ping
  useEffect(() => {
    if (sessionId && !lastHeartbeatTime && !serviceIsRunning) {
      const helper = async () => {
        await handlePingAlive()
        _lastHeartbeatTime(new Date().getTime())
      }
      helper()
    }
  })

  const openInGoogleMaps = (latitude, longitude) => {
    window.open(`https://maps.google.com?q=${latitude},${longitude}`)
  }

  const handleSaveLocation = async (
    latitude,
    longitude,
    isRealLocation,
    locationName,
  ) => {
    const res = await updateBatchFinderServiceLocation(
      sessionId,
      latitude,
      longitude,
      isRealLocation,
      locationName,
    )
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else {
        showAlert(
          'Error saving location',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
      handleGetServiceSettings()
    } else if (res.statusCode === 0) {
      // No-op
    } else {
      showAlert(
        'Error saving location',
        `Raw Error: ${JSON.stringify(res)}`,
        'client_error',
      )
    }
  }

  return (
    <div class="noselect">
      <BatchFinderFocusBatch
        batch={focusBatch}
        setBatch={setFocusBatch}
        sessionId={sessionId}
        showAlert={showAlert}
      />
      <FakeLocationSelector
        visible={locationSelectorVisible}
        hideFakeGPS={() => _locationSelectorVisible(false)}
        storeLocations={storeLocations}
        realLatitude={realLatitude}
        realLongitude={realLongitude}
        handleSaveLocation={handleSaveLocation}
        usingRealLocation={locationIsReal}
        currentSettingLatitude={locationLatitude}
        currentSettingLongitude={locationLongitude}
        currentSettingLocationName={locationName}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            paddingTop: '20px',
          }}
        >
          BatchFinder
        </h1>
        {!serviceIsRunning && (
          <>
            <NeuDiv
              style={{
                width: '95vw',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'scroll',
                marginTop: '10px',
                justifyContent: 'space-between',
                padding: '15px 0px 30px 0px',
              }}
              color={colors.offWhite}
              distance={2}
              revert
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {!locationIsReal && locationName && (
                  <p
                    style={{
                      color: colors.black,
                      textAlign: 'center',
                      padding: '5px 10px 0px 10px',
                      fontSize: '0.9em',
                      fontWeight: 400,
                    }}
                  >
                    Current Faked Location: {locationName}
                  </p>
                )}
                {locationLatitude && locationLongitude && (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      width: 'auto',
                      margin: '10px 0px 2px 0px',
                      fontSize: '14px',
                    }}
                    onClick={() =>
                      openInGoogleMaps(locationLatitude, locationLongitude)
                    }
                  >
                    View Selected Location
                  </Button>
                )}
                {storeLocations && realLatitude && realLongitude && (
                  <Button
                    variant="contained"
                    sx={{
                      width: 'auto',
                      margin: '10px 0px 2px 0px',
                      fontSize: '14px',
                    }}
                    onClick={() => _locationSelectorVisible(true)}
                  >
                    Set Location
                  </Button>
                )}
              </div>
              {!currentLicense && (
                <>
                  <p
                    style={{
                      color: colors.red,
                      textAlign: 'center',
                      paddingTop: '10px',
                      fontSize: '0.9em',
                      fontWeight: 500,
                    }}
                  >
                    License required. Trial license keys are available on the
                    License page.
                  </p>
                  <Button
                    variant="contained"
                    style={{
                      width: 'auto',
                    }}
                    onClick={() =>
                      navigate({
                        pathname: '/license',
                        search: searchParams.toString(),
                      })
                    }
                  >
                    Click here to view licenses
                  </Button>
                </>
              )}
              {currentLicense &&
                !locationLatitude &&
                hasRetrievedServiceSettings && (
                  <p
                    style={{
                      color: colors.red,
                      textAlign: 'center',
                      padding: '10px 20px 0px 20px',
                      fontSize: '0.9em',
                      fontWeight: 400,
                    }}
                  >
                    <b>
                      Location required. Please set your location above to run
                      BatchFinder.
                    </b>
                    <br />
                    <br />
                    <br />
                    {storeLocations &&
                      `If you don't see the "SET LOCATION" button above. Make sure
                    you have enabled Location Services for your web browser.`}
                    {!storeLocations &&
                      (!realLatitude || !realLongitude) &&
                      'Please check if Location Services are enabled for your web browser and specifically for this website. Your location is required to retrieve nearby stores.'}
                    {!storeLocations &&
                      realLatitude &&
                      realLongitude &&
                      'Retrieving nearby store locations...'}
                  </p>
                )}
              <Button
                variant="contained"
                color="success"
                sx={{ marginTop: '20px', fontSize: '25px' }}
                onClick={handleStartService}
                disabled={
                  !currentLicense || !locationLatitude || !locationLongitude
                }
              >
                START SERVICE
              </Button>
              {serviceStopReason && (
                <p
                  style={{
                    color: colors.black,
                    textAlign: 'center',
                    padding: '5px 10px 0px 10px',
                    fontSize: '0.7em',
                    fontWeight: 400,
                  }}
                >
                  <b>Last Service Stopped Due To:</b> {serviceStopReason}
                </p>
              )}
            </NeuDiv>
          </>
        )}
        {serviceIsRunning && (
          <>
            <p
              style={{
                textAlign: 'center',
                color: 'red',
                fontSize: '14px',
                marginTop: '10px',
                marginBottom: '5px',
                fontWeight: 500,
              }}
            >
              BatchFinder will run in the background
            </p>
            <NeuDiv
              style={{
                width: '95vw',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'scroll',
              }}
              color={colors.offWhite}
              distance={2}
              revert
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    fontWeight: 500,
                    fontSize: '20px',
                    color: colors.black,
                  }}
                >
                  Available Batches: {batches?.length}
                </p>
                <ProgressCircular
                  indeterminate
                  size={24}
                  width={2}
                  color="var(--primary)"
                  style={{
                    marginLeft: '20px',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                  }}
                />
              </div>

              {batches.map((item) => (
                <BatchListItem
                  key={item.acceptLink}
                  batch={item}
                  onClick={() => setFocusBatch(item)}
                />
              ))}
            </NeuDiv>
          </>
        )}
      </div>
      {serviceIsRunning && (
        <Button
          variant="contained"
          color="error"
          sx={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            fontSize: '12px',
          }}
          onClick={handleStopService}
        >
          Stop
        </Button>
      )}
      <BackButton />
    </div>
  )
}
