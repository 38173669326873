import { Link } from 'react-router-dom'
import { colors } from '../../colors'
import { useState } from 'react'

export const StandardCard = ({
  handleGetLicensePricing,
  handleGetLicenseLink,
  pricingConfig,
}) => {
  const [licenseLength, _licenseLength] = useState('30_days')

  const displayPrice = handleGetLicensePricing(
    pricingConfig,
    'standard',
    licenseLength,
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        padding: '0.5rem 0.5rem',
        backgroundColor: '#FBFAF8',
        borderRadius: '0px 0px 15px 15px',
      }}
    >
      <p style={{ fontSize: '4.5em', marginTop: '-4rem' }}>⭐</p>
      <h2
        style={{
          color: '#1070FF',
          fontWeight: 800,
          paddingTop: '0.3rem',
          fontSize: '28px',
        }}
      >
        Standard
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#1070FF',
          padding: '4px',
          marginTop: '0.5rem',
        }}
      >
        <p style={{ color: '#FBFAF8', fontSize: '0.7em' }}>ESSENTIAL CHOICE</p>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: '4px',
          marginTop: '1rem',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🤑</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Auto accepts batches
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>⚡</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Fast performance
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>🔀</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Limited (10) filter profiles
        </p>
        <p style={{ fontSize: '24px', justifySelf: 'center' }}>📱</p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          Compatible with all devices
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: '2.5rem',
        }}
      >
        {!!displayPrice && (
          <p
            style={{
              color: colors.splashDark,
              fontWeight: 700,
            }}
          >
            ${displayPrice}
          </p>
        )}
        <p
          style={{
            fontSize: '10px',
            color: '#515151',
            margin: '2px 0px',
          }}
        >
          One-time purchase, no re-occuring fees
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          minWidth: '250px',
          backgroundColor: '#D9D9D9',
          padding: '2px',
          borderRadius: '5px',
          marginTop: '5px',
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor:
              licenseLength === '30_days' ? '#FBFAF8' : 'transparent',
            borderRadius: '4px',
            padding: '4px 0px',
            marginRight: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength('30_days')}
        >
          30 Days
        </div>
        {licenseLength === '1_day' && (
          <div
            style={{
              margin: '4px 0px',
              width: '1px',
              backgroundColor: '#9F9F9F',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            backgroundColor:
              licenseLength === '7_days' ? '#FBFAF8' : 'transparent',
            borderRadius: '4px',
            padding: '4px 0px',
            margin: '0px 1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength('7_days')}
        >
          7 Days
        </div>
        {licenseLength === '30_days' && (
          <div
            style={{
              margin: '4px 0px',
              width: '1px',
              backgroundColor: '#9F9F9F',
            }}
          />
        )}
        <div
          style={{
            flex: 1,
            backgroundColor:
              licenseLength === '1_day' ? '#FBFAF8' : 'transparent',
            borderRadius: '4px',
            padding: '4px 0px',
            marginLeft: '1px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => _licenseLength('1_day')}
        >
          1 Day
        </div>
      </div>
      <Link
        to={`./${handleGetLicenseLink('standard', licenseLength)}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '10px',
          height: '3rem',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#1070FF',
          color: 'white',
          fontSize: '20px',
          fontWeight: 900,
          marginTop: '1rem',
          textDecoration: 'none',
          transition: 'transform 0.1s, filter 0.1s',
        }}
        onTouchStart={(e) => {
          e.currentTarget.style.transform = 'scale(0.95)'
        }}
        onTouchEnd={(e) => {
          e.currentTarget.style.transform = 'scale(1)'
        }}
      >
        Purchase Now
      </Link>
    </div>
  )
}
