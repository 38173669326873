import { useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../colors'
import { Header } from './Header'
import { StartButton } from './StartButton'
import { Features } from './Features'
import { Testimonials } from './Testimonials'
import { FadeIn } from './FadeIn'
import { Helmet } from 'react-helmet'

export const SplashScreen = ({ isLoggedIn }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn?.isLoggedIn === true) {
      navigate({
        pathname: '/app',
        search: searchParams.toString(),
      })
    }
  }, [isLoggedIn])

  return (
    <div class="noselect">
      <Helmet>
        <title>BatchFinder - The Best Instacart Bot</title>
        <meta
          name="description"
          content="Auto-accept the highest paying Instacart batches with BatchFinder. Join thousands of shoppers earning more. Boost your earnings effortlessly. Try BatchFinder for free today."
        />
        <meta
          name="keywords"
          content="Instacart bot, batch grabber, BatchFinder, auto-accept batches, instacart bot, highest paying batches"
        />
        <link rel="canonical" href={`https://avalon.rip`} />
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100vw',
          minHeight: '100vh',
          backgroundColor: colors.splashDark,
          color: 'white',
          padding: '0px 10px 50px 10px',
          justifyContent: 'flex-start',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
        }}
      >
        <Header />
        <div style={{ textAlign: 'center', padding: '120px 0px 40px 0px' }}>
          <h1>Get Better Batches.</h1>
          <h1
            style={{
              color: colors.splashGreen,
            }}
          >
            Earn 30% More
          </h1>
        </div>
        <div
          style={{
            padding: '0 2.5rem',
            textAlign: 'center',
            fontSize: '18px',
            color: '#C2C3C5',
          }}
        >
          <p>Auto-accept the highest paying Instacart batches.</p>
          <p style={{ color: colors.splashGreen }}>You Deserve It.</p>
        </div>
        <div style={{ padding: '1.5rem 0 4rem 0' }}>
          <StartButton>Get Started</StartButton>
        </div>
        <FadeIn>
          <div
            style={{
              textAlign: 'center',
              padding: '10vh 0px 40px 0px',
              fontSize: '0.95em',
            }}
          >
            <h1>Join Thousands of Shoppers earning more with BatchFinder</h1>
          </div>
        </FadeIn>
        <Features />
        <div
          style={{
            textAlign: 'center',
            padding: '30px 0px 20px 0px',
            fontSize: '0.95em',
          }}
        >
          <h1>100% Safe to Use</h1>
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '18px',
            color: '#C2C3C5',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: '#2e2e2e',
          }}
        >
          <p style={{ color: '#fff', fontWeight: 700 }}>
            0 Client Accounts Deactivated
          </p>
          <p style={{ paddingTop: '0.2rem' }}>
            since the release of BatchFinder.
          </p>
          <p
            style={{
              color: colors.splashGreen,
              paddingTop: '0.2rem',
              fontWeight: 600,
            }}
          >
            Completely Undetectable.
          </p>
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '40px 0px 20px 0px',
            fontSize: '0.95em',
          }}
        >
          <h1>Trusted by Thousands of Shoppers since 2020</h1>
        </div>
        <Testimonials />
        <div
          style={{
            textAlign: 'center',
            padding: '30px 0px 20px 0px',
            fontSize: '0.95em',
          }}
        >
          <h1>Ready to Boost Your Earnings?</h1>
        </div>
        <div
          style={{
            padding: '0 2rem',
            textAlign: 'center',
            fontSize: '18px',
            color: '#C2C3C5',
          }}
        >
          <p style={{ color: colors.white }}>Try BatchFinder risk-free.</p>
          <p style={{ color: colors.splashGreen }}>No payment info required.</p>
        </div>
        <div style={{ padding: '1.5rem 0 4rem 0' }}>
          <StartButton>Start for Free</StartButton>
        </div>
      </div>

      <footer
        style={{
          width: '100%',
          padding: '1rem 0',
          textAlign: 'center',
          backgroundColor: colors.splashDark,
        }}
      >
        <Link
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: colors.white,
          }}
          to="/blog"
        >
          Blog
        </Link>
      </footer>
    </div>
  )
}
