import { NeuButton } from 'neumorphism-react'
import { Button, Card, Dialog, Tooltip } from 'ui-neumorphism'
import { colors } from '../../colors'
import { Chip } from 'ui-neumorphism'
import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded'

//AIDOC: List of setup instructions for DoorDash users
export const DoorDashInstructionsDialog = ({
  visible,
  hideDialog,
  instructions,
}) => {
  return (
    <Dialog minWidth={300} visible={visible} onClose={hideDialog}>
      <Card
        style={{
          justifyContent: 'center',
          padding: '10px 0px 5px 0px',
          maxWidth: '90vw',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px 10px 10px 10px',
          }}
        >
          <p
            style={{
              fontWeight: 600,
              fontSize: '20px',
              marginBottom: '10px',
            }}
          >
            Customer Instructions
          </p>
          <p>{instructions}</p>
          <NeuButton
            style={{
              width: '40vw',
              height: '30px',
              textTransform: 'none',
              fontSize: '18px',
              color: colors.black,
              marginTop: '20px',
            }}
            color={colors.offWhite}
            distance={2}
            onClick={hideDialog}
          >
            CLOSE
          </NeuButton>
        </div>
      </Card>
    </Dialog>
  )
}
